import { KeyboardEvent } from "react";

export function isValidDate(year:number, month:number, day:number): boolean {
  const d = new Date(year, month - 1, day);
  if (
    d.getFullYear() === year &&
      year.toString().length === 4 &&
      d.getMonth() === month - 1 &&
      d.getDate() === day
  ) {
    return true;
  }
  return false;
}

export function isValidPANStructure(panNumber: string): boolean {
  const regex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
  return regex.test(panNumber);
}

export function isValidEmailStructure(email: string): boolean {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export function isSpecialCharactersOrDigits(value: string): boolean {
  const regex = /^[a-zA-Z ]+$/;
  return !regex.test(value);
}

export function isValidPercentage(value: string | number): boolean {
  if (Number.isNaN(value) || +value < 0 || +value > 100) {
    return false;
  }
  return true;
}

export const testMinMaxScores: Record<string, Record<string, number>> = {
  GRE: {
    min: 130,
    max: 170,
  },
  GMAT: {
    min: 0,
    max: 60
  },
  TOEFL: {
    min: 1,
    max: 120
  },
  IELTS: {
    min: 1,
    max: 9
  },
  DUOLINGO: {
    min: 10,
    max: 160
  }
};

export const handleKeyPressForNumericInputs = (e: KeyboardEvent) => {
  const { key } = e;
  const preventKeys = ["e", "E", "+", "-", "ArrowUp", "ArrowDown"];
  if (preventKeys.includes(key)) {
    e.preventDefault();
  }
};
